export const GET_TOKEN_REQUEST = "GET_TOKEN_REQUEST";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const GET_DEVICE_COUNT_REQUEST = "GET_DEVICE_COUNT_REQUEST";
export const GET_DEVICE_COUNT_SUCCESS = "GET_DEVICE_COUNT_SUCCESS";
export const GET_DEVICE_COUNT_ERROR = "GET_DEVICE_COUNT_ERROR";

export const GET_DEVICE_DATA_REQUEST = "GET_DEVICE_DATA_REQUEST";
export const GET_DEVICE_DATA_SUCCESS = "GET_DEVICE_DATA_SUCCESS";
export const GET_DEVICE_DATA_ERROR = "GET_DEVICE_DATA_ERROR";

export const GET_DEVICE_APP_DATA_REQUEST = "GET_DEVICE_APP_DATA_REQUEST";
export const GET_DEVICE_APP_DATA_SUCCESS = "GET_DEVICE_APP_DATA_SUCCESS";
export const GET_DEVICE_APP_DATA_ERROR = "GET_DEVICE_APP_DATA_ERROR";

export const GET_TICKET_COUNT_REQUEST = "GET_TICKET_COUNT_REQUEST";
export const GET_TICKET_COUNT_SUCCESS = "GET_TICKET_COUNT_SUCCESS";
export const GET_TICKET_COUNT_ERROR = "GET_TICKET_COUNT_ERROR";

export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_ERROR = "GET_TICKETS_ERROR";

export const GET_TICKET_TOP_ISSUES_REQUEST = "GET_TICKET_TOP_ISSUES_REQUEST";
export const GET_TICKET_TOP_ISSUES_SUCCESS = "GET_TICKET_TOP_ISSUES_SUCCESS";
export const GET_TICKET_TOP_ISSUES_ERROR = "GET_TICKET_TOP_ISSUES_ERROR";

export const GET_TICKET_LIST_REQUEST = "GET_TICKET_LIST_REQUEST";
export const GET_TICKET_LIST_SUCCESS = "GET_TICKET_LIST_SUCCESS";
export const GET_TICKET_LIST_ERROR = "GET_TICKET_LIST_ERROR";

export const GET_TICKET_DATA_REQUEST = "GET_TICKET_DATA_REQUEST";
export const GET_TICKET_DATA_SUCCESS = "GET_TICKET_DATA_SUCCESS";
export const GET_TICKET_DATA_ERROR = "GET_TICKET_DATA_ERROR";

export const SAVE_STATE = "SAVE_STATE";
